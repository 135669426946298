<template>
    <div class="defaultHome">
        <headerBar></headerBar>
        <div class="defaultHome-lump"></div>
        <div class="defaultHome-content-wrap">
            <menuBar class="menuBar"></menuBar>
            <div class="main-wrap">
                <router-view></router-view>
            </div>
        </div>

    </div>
</template>

<script>
import headerBar from './layout/headerBar'
import menuBar from './layout/menuBar'
export default {
    // beforeCreate() {
    //     let token = this.$methods.getToken()
    //     if (token !== null) {
    //     // this.$router.push("/defaultHome");
    //     } else {
    //         this.$Message.warning('登录信息过期');
    //         this.$router.push("/signIn");
    //     }
    // },
    components: {
        headerBar,
        menuBar
    }
}
</script>

<style lang="scss">
.defaultHome{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .defaultHome-lump{
        height: 60px;               //同步top高度
    }
    .defaultHome-content-wrap{
        height: calc(100% - 60px);  //同步top高度
        box-sizing: border-box;
          
        .menuBar{
            position: fixed;
        }
        .main-wrap{
            margin-left: 250px;
            width: calc(100% - 250px);
            height: 100%;
            box-sizing: border-box;
            background-color: #F5F6F7;
            // border: 1px solid green;
            min-width: 1000px;
            overflow: auto;
        }
    }
}
</style>