<template>
    <div class="default-menuBar">  
        <!-- <div class="profile-wrap">
            <div class="profile">
                <img src="@/assets/sideBar/missing-face.png" alt="">
                <div class="wechat-info">   
                    <div class="name">{{userInfo.username}}</div>
                    <div class="welcome">欢迎回来</div>
                </div>
            </div>
        </div> -->

        <el-menu
            :default-active="curretMenuIndex"
            router
            :unique-opened="true"  
            @select="selectIndex"          
        >
            <template v-for="(item,index) in menuList">
                <el-submenu :index="item.index" v-if='item.sbuMenu' :key="item.id">
                    <template slot="title">        
                        <i :class="item.icon"></i>      
                        <span>{{item.title}}</span>
                    </template>                 
                    <el-menu-item-group v-for="(subItem,subindex) in item.subs" :key="subindex">
                        <el-menu-item :key="subindex" :index="subItem.index" style="padding-left:55px">{{subItem.title}}</el-menu-item>
                    </el-menu-item-group>                           
                </el-submenu>
                <el-menu-item v-else :index="item.index" :key="index">
                    <i :class="item.icon"></i>
                    <span slot="title">{{item.title}}</span>
                </el-menu-item>
                <!-- <el-submenu index="ad">
                    <template slot="title">        
                        <i class="iconfont icon-xiaoshou"></i>      
                        <span>广告计划管理</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item index="tencentAdList">腾讯广告</el-menu-item>
                    </el-menu-item-group>
                </el-submenu> -->
            </template>

        </el-menu>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    mounted () {
        this.menuList = this.$menu.adminMenu 

        // console.log(this.userInfo);
        // if(this.userInfo){
        //     if(this.userInfo.parentId === 0){
        //         this.menuList = this.$menu.adminMenu            //admin
        //         this.selectIndex('admin')
        //     }else{
        //         if(this.userInfo.roleId === 5){
        //             this.menuList = this.$menu.agencyMenu         //代理
        //             this.selectIndex('agency')
        //         }else{
        //             this.menuList = this.$menu.oemMenu          //oem
        //             this.selectIndex('business')
        //         }
        //     }
        //     // console.log(this.menuList);
        // }     
    },
    computed: {
      curretMenuIndex(){
          return this.$store.state.curretMenuIndex
      }
    },
    data() {
        return {
            menuList:[],
            // userInfo:this.$methods.getUserInfo(),
            defaultActive:""
        }
    },
    methods: {
        ...mapMutations(['setCurretMenuIndex']),
        selectIndex(index){
            // console.log(index);
            // this.setCurretMenuIndex(index)
        }
    }
}
</script>

<style lang="scss">
.default-menuBar{
    width: 250px;
    height: 100%;
    box-sizing: border-box;
    // box-shadow: 0px 5px 10px 0px #ECEEF1; 
    // border-right: 1px solid #ccc;
    // border: 1px solid red;
    // background-color: $sideBgColor;
    z-index: 98;
    // background-color: #fff;
    background-color: #0B1529;  
    .profile-wrap{
        height: 220px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        .profile{
            box-sizing: border-box;
            img{
                border: 1px solid #E8E8E8;
                border-radius: 50px;
                padding: 3px 3px;
                box-sizing: border-box;                
                width: 80px;
                height: 80px;
                box-shadow: 0px 0px 10px #ccc;
            }
            .wechat-info{
                margin-top: 8px;
                text-align: center;
                .name{
                    font-weight: 600;
                    color: #2C2C2C;
                }
                .welcome{
                    margin-top: 5px;
                    font-size: 12px;
                    color: #9B9B9B;
                }
            }
        }
        &::after{
            content: '';
            position: absolute;
            height: 1px;
            width: 85%;
            background-color: #E0E0E0;
            bottom: 0;
            left: 20px;
        }
    }
    .el-menu{
        width: 100%;
        height: calc(100% - 220px);
        border-right:none;
        // border: 1px solid red;
        text-align: left;
        // padding-left: 10px;
        box-sizing: border-box;
        background-color: #0B1529;        
        .iconfont{
            margin-right: 15px;
            font-size: 18px;
            font-weight: 500;
            color: #fff;
        }       
        .el-menu-item{
            // color: #fff;
            // font-weight: 600;
            color: #909399;
            font-size: 15px;
        }     
        .el-submenu{
            .el-submenu__title{
                font-size: 15px;
                color: #909399;
                
            }
            .el-menu-item{
                font-size: 14px;
                color: #909399;
                
            }      
            .el-menu-item-group{
            }    
        } 
        .el-menu-item:hover{
            background-color: #182848;
            color: #909399;
            .iconfont{
                color: #909399;
            }
        }
        .el-submenu__title:hover{
            background-color: #182848;
            color: #909399;
            .iconfont{
                color: #909399;
            }        
        }          
        .el-menu-item:focus{
            color: #fff;
            background-color: #182848;
        }  
        .el-menu-item.is-active{
            color: #fff;
            i{
                color: #fff;
            }
        } 
        .el-submenu.is-active>.el-submenu__title{
            color: #fff;
            
            i{
                color: #fff;
            }
        }  
    }

 

  
  


    
}


</style>