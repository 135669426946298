<template>
    <div class="default-headerBar">
        <div class="Logo">博雅敏格</div>
        <div class="tool-bar">
            <div class="profile-wrap">
                <div class="img-wrap">
                    <img src="@/assets/sideBar/missing-face.png" alt="">
                </div>
                <div class="companyName">品牌主 {{userInfo.name}}</div>
            </div>
            <div class="logout" @click="handleLogout">
                <!-- <i class="el-icon-remove off"></i> -->
                <div class="off">退出登录</div>
            </div>
        </div>
    </div>
</template>

<script>
import { saLogout } from "@/methods";
import { localSave } from "@/methods/util";
export default {
    data() {
        return {
            menuList:[],
            userInfo: {}
        }
    },
    mounted(){
        setTimeout(()=>{
            this.userInfo = JSON.parse(sessionStorage.getItem('byUserInfo'));
        },200)
    },
    methods: {
        handleLogout(){
            this.$API.logout().then( res => {
                if(res.code === 0){
                    saLogout();
                    // location.href = '#/admin';
                    this.$router.push('/')    
                    this.$message.success("已成功清除登录信息");  
                }
            })
        }
    },
    computed: {
        adAccountName(){
            return this.$store.state.adAccountName
        }
    }
}
</script>

<style lang="scss">
.default-headerBar{
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    // border-bottom: 1px solid #ccc;
    // box-shadow: 0px 5px 10px -5px #ccc;   
    // background-color: #c6c6c6; 
    // background-image: linear-gradient(to right, #fff , #296BEF);
    background-color: $headerBgColor;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 999;
    background-color: #fff;
    .Logo{
        width: 250px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        background-color: #0B1529; 
        // color: #00BCD4;
        color: $logoColor;
        // font-weight: 600;
    }
    .tool-bar{
        height: 100%;
        display: flex;
        align-items: center;
        // border: 1px solid red;
        box-sizing: border-box;
        .profile-wrap{
            display: flex;
            align-items: center;
            .img-wrap{
                width: 23px;
                height: 23px;
                margin-right: 10px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .companyName{
                color: #000;
                // letter-spacing: 1px;
            }
        }
        .logout{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px;
            font-size: 15px;
            cursor: pointer;
            .off{
                font-size: 15px;
                padding: 2px;
                color: #606266;
            }
            &:hover{
                text-shadow:.2rem 0rem .5rem #ccc,-.2rem 0rem .5rem #ccc,0rem .2rem .5rem #ccc,0rem -.2rem .5rem #ccc;                
            }
        }
    }
}
</style>